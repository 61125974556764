import React from "react";
import Layout from "../components/common/layout";
import { PageProps, graphql } from "gatsby";
import Seo from "../components/common/seo";
import { GatsbyImage } from "gatsby-plugin-image";
import { siteConfig } from "../utils/helpers";

const AboutPage = ({ data, location }: PageProps<Queries.AboutQuery>) => (
  <Layout isWide>
    <Seo
      title={`About - ${data.site?.siteMetadata?.title}`}
      origin={location.origin}
      image={`${data.image?.publicURL}`}
    />
    <header className="mb-8 text-center">
      <h1 className="text-3xl font-bold inline">About</h1>
    </header>
    <div className="flex flex-wrap md:justify-between lg:justify-around">
      <div className="w-full md:w-1/2 lg:w-2/5">
        <GatsbyImage
          //@ts-ignore
          image={data.image?.childImageSharp?.gatsbyImageData}
          alt="the site author in a park, smiling and looking down on the ground."
          className="-mx-4 md:mx-0"
        />
      </div>

      <div className="w-full md:w-[45%] lg:w-2/5 max-w-[65ch] md:max-w-full mx-auto md:mx-0 prose dark:prose-invert dark:text-white mt-8 md:mt-0">
        <h2>What is this?</h2>
        <p>
          This is a photo blog, featuring images that I've (mostly) captured
          myself. I wanted my own dedicated platform for uploading pictures with
          no common theme and no toxic comments, so I built this from scratch.
          Think of it as like a stripped back version of Instagram.
        </p>
        <h2>Who are you?</h2>
        <p>
          My name is Ayotunde Ikuesan and I am a web developer &amp; software engineer.
          I enjoy working on little projects like this in my spare time and
          have&nbsp;
          <a href={siteConfig.authorUrl}>another blog</a> where I speak in
          binary and write about some more technical topics.
        </p>
        <p>
          Outside of programming though, I have a real drive for being creative.
          I'm not a professional photographer by any means, nor do I class these
          images as being eye-searingly beautiful, I just think they look cool.
          One thing you should absolutely know is that&nbsp;
          <a href="/" className="font-bold">
            lensi.xyz
          </a>
          &nbsp; (<em>Lensi</em> being the Yoruba-ish word for "lens") was
          created for myself, firstly.
        </p>
        <h2>Credits</h2>
        <p>
          This website was built using the&nbsp;
          <a href="https://www.gatsbyjs.com">GatsbyJS</a> framework and styled
          with <a href="https://tailwindcss.com">Tailwind CSS</a>. I used
          the&nbsp;
          <a href="https://philatype.com/sen">Sen</a> and&nbsp;
          <a href="https://www.ibm.com/plex/">IBM Plex Mono</a> typefaces for
          the fonts, along with the&nbsp;
          <a href="https://icons.getbootstrap.com/">Bootstrap Icons</a>&nbsp;
          library.
        </p>
        <p>
          The images and content featured throughout this website are my own and
          are not intended for reuse of any kind. ✌🏾
        </p>
      </div>
    </div>
  </Layout>
);

export default AboutPage;

export const aboutQuery = graphql`
  query About {
    site {
      siteMetadata {
        title
      }
    }
    image: file(relativePath: { eq: "ayotunde.jpg" }) {
      publicURL
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
